const projects = [
    {
        name: "Onboarder - Swift Package",
        date: "2022",
        description: "Onboarder is a Swift Package for creating onboarding screens for SwiftUI or UIKit appps.",
        img: 'onboarder.png',
        live: 'https://github.com/JemAlvarez/onboarder',
        code: 'https://github.com/JemAlvarez/onboarder'
    },{
        name: "Gyfter - Occasion Reminder",
        date: "2022",
        description: "Gyfter helps you rember those special occasions you know you shoudln't forget",
        img: 'gyfter.png',
        live: 'https://apps.apple.com/us/app/id1615132443',
        code: 'https://apps.apple.com/us/app/id1615132443'
    },{
        name: "Movieholic",
        date: "2022",
        description: "Movie database app for MacOS",
        img: 'movies_macos.png',
        live: 'https://github.com/JemAlvarez/Movieholic',
        code: 'https://github.com/JemAlvarez/Movieholic'
    },{
        name: "Sketchoo",
        date: "2022",
        description: "Drawing app for iOS and iPadOS with PencilKit",
        img: 'sketchoo.png',
        live: 'https://apps.apple.com/us/app/sketchoo-drawing-app/id1606842517',
        code: 'https://github.com/JemAlvarez/Pokedex_SwiftUI_iOS15'
    },{
        name: "Screenly - Mockup Screenshots",
        date: "2021",
        description: "Screenly is a highly customizable screenshot mockup image generator.",
        img: 'screenly.png',
        live: 'https://apps.apple.com/us/app/screenly-mockup-screenshots/id1592798429',
        code: 'https://apps.apple.com/us/app/screenly-mockup-screenshots/id1592798429'
    },{
        name: "Pokedex App",
        date: "2021",
        description: "Pokdex app for iOS, all pokemon, moves, items information.",
        img: 'pokedex.png',
        live: 'https://github.com/JemAlvarez/Pokedex_SwiftUI_iOS15',
        code: 'https://github.com/JemAlvarez/Pokedex_SwiftUI_iOS15'
    },{
        name: "Covid Tracker",
        date: "2021",
        description: "Worldwide covid data and specif countries and states.",
        img: 'covid.png',
        live: 'https://github.com/JemAlvarez/COVID_Tracker_SwiftUI_iOS14',
        code: 'https://github.com/JemAlvarez/COVID_Tracker_SwiftUI_iOS14'
    },{
        name: "Weather App",
        date: "2021",
        description: "Weather app using SwiftUI 2, CoreLocation & CoreData.",
        img: 'weather-ios.png',
        live: 'https://github.com/JemAlvarez/WeatherApp_SwiftUI_iOS14',
        code: 'https://github.com/JemAlvarez/WeatherApp_SwiftUI_iOS14'
    },{
        name: "Notes App",
        date: "2021",
        description: "Notes app using SwiftUI 2 and CoreData.",
        img: 'notes-ios.png',
        live: 'https://github.com/JemAlvarez/NotesApp_SwiftUI_iOS14',
        code: 'https://github.com/JemAlvarez/NotesApp_SwiftUI_iOS14' 
    },{
        name: "Card Memory Game",
        date: "2021",
        description: "Classic card memory game with different difficulties inspired by the SuperSmashBros game.",
        img: 'card-memory-game.png',
        live: 'https://github.com/JemAlvarez/MemoryCardGame_SwiftUI_iOS14',
        code: 'https://github.com/JemAlvarez/MemoryCardGame_SwiftUI_iOS14'
    },{
        name: "iOS Movies App",
        date: "2020",
        description: "Movie database app for iOS",
        img: 'movies_ios.png',
        live: 'https://github.com/JemAlvarez/iOS-Movies',
        code: 'https://github.com/JemAlvarez/iOS-Movies'
    },{
        name: "iOS Calculator",
        date: "2020",
        description: "Calculator app for iOS with swipe to delete",
        img: 'calculator_ios.png',
        live: 'https://github.com/JemAlvarez/Calculator_iOS13',
        code: 'https://github.com/JemAlvarez/Calculator_iOS13'
    },{
        name: "QuickSort Visualizer",
        date: "2019",
        description: "Visualizing quicksort algorithm in javascript.",
        img: 'qs.png',
        live: 'https://quicksort.jemalvarez.com/',
        code: 'https://github.com/JemAlvarez/QuickSort-JS'
    }, {
        name: "Analog Clock",
        date: "2019",
        description: "Simple analog clock",
        img: 'clock.png',
        live: 'https://clock.jemalvarez.com/',
        code: 'https://github.com/JemAlvarez/JS-Analog-Clock'
    }, {
        name: "Jungle Escape",
        date: "2019",
        description: "Simple escape platformer game, made in unity.",
        img: 'jungle-escape.png',
        live: '',
        code: 'https://github.com/JemAlvarez/Jungle-Escape'
    },{
        name: "Pong Game",
        date: "2019",
        description: "Atari pong game clone, made in unity",
        img: 'pong.png',
        live: '',
        code: 'https://github.com/JemAlvarez/PongGame'
    }, {
        name: "Space Invaders Game",
        date: "2019",
        description: "Space invaders game variant, made in unity",
        img: 'space-invaders.png',
        live: '',
        code: 'https://github.com/JemAlvarez/Space-Invaders'
    }, {
        name: "Plants vs Zombies",
        date: "2019",
        description: "Plants vz Zombie game clone, made in unity",
        img: 'pvz.png',
        live: '',
        code: 'https://github.com/JemAlvarez/PVZ-Clone'
    }, {
        name: "Block Breaker Game",
        date: "2019",
        description: "Block breaking game, made in unity",
        img: 'block-breaker.png',
        live: '',
        code: 'https://github.com/JemAlvarez/block-breaker-unity'
    }, {
        name: "Recipe App API",
        date: "2019",
        description: "Backend api for a recipes app",
        img: 'recipes.png',
        live: '',
        code: 'https://github.com/JemAlvarez/recipes-app-api'
    }, {
        name: "League of Legends App",
        date: "2019",
        description: "League of Legends stats app",
        img: 'lol-app.png',
        live: 'https://lol.jemalvarez.com/',
        code: 'https://github.com/JemAlvarez/lol-app-react'
    }, {
        name: "League of Legends API",
        date: "2019",
        description: "Custom league of legends backend api",
        img: 'lol-api.png',
        live: 'https://ja-lol-app.herokuapp.com/',
        code: 'https://github.com/JemAlvarez/lol-app'
    }, {
        name: "Chat App",
        date: "2019",
        description: "Chat app with rooms",
        img: 'chat-app.png',
        live: 'https://ja-chat-app.herokuapp.com/',
        code: 'https://github.com/JemAlvarez/chat-app'
    }, {
        name: "Login Page",
        date: "2019",
        description: "Login page with ReactJS and ExpressJS/MongoDB",
        img: 'login-page.png',
        live: 'https://login-page.jemalvarez.com/',
        code: 'https://github.com/JemAlvarez/login-page'
    }, {
        name: "Discord Bot",
        date: "2019",
        description: "Discord bot using discord.js",
        img: 'discord-bot.png',
        live: '',
        code: 'https://github.com/JemAlvarez/stast-discord-bot'
    }, {
        name: "Weather App",
        date: "2019",
        description: "Get forecast for any location (Front-end)",
        img: 'weather.png',
        live: 'https://weather.jemalvarez.com/',
        code: 'https://github.com/JemAlvarez/weather-app-react'
    }, {
        name: "Weather API",
        date: "2019",
        description: "Get forecast for any location (Back-end)",
        img: 'weather-api.png',
        live: 'https://ja-forecast-api.herokuapp.com/weather?address=us',
        code: 'https://github.com/JemAlvarez/forecast-api'
    }, {
        name: "Movie App",
        date: "2018-2019",
        description: "Information about movies/shows/actors from movie database.",
        img: "movies.png",
        live: 'https://movies.jemalvarez.com/',
        code: 'https://github.com/JemAlvarez/Movie-App'
    }, {
        name: "Expenses Manager",
        date: "2018",
        description: "Manage your expenses (login required).",
        img: "expenses.png",
        live: 'https://expensify-app.jemalvarez.com/',
        code: 'https://github.com/JemAlvarez/Expensify-App'
    }, {
        name: "Notes app",
        date: "2018",
        description: "Note taking app.",
        img: "notes.png",
        live: 'https://notes.jemalvarez.com/',
        code: 'https://github.com/JemAlvarez/Notes-App'
    }, {
        name: "Indecision App",
        date: "2018",
        description: "Let a computer pick a random choice for you.",
        img: "indecision.png",
        live: 'https://indecision-app.jemalvarez.com/',
        code: 'https://github.com/JemAlvarez/Indecision-App'
    }, {
        name: "Hangman Game",
        date: "2018",
        description: "Guess randomly generated words.",
        img: "hangman.png",
        live: 'https://hangman.jemalvarez.com/',
        code: 'https://github.com/JemAlvarez/Hangman'
    }, {
        name: "To-do App",
        date: "2018",
        description: "Create a to-do list.",
        img: "todo.png",
        live: 'https://todos.jemalvarez.com/',
        code: 'https://github.com/JemAlvarez/Todo-App'
    }, {
        name: "Calculator App",
        date: "2018",
        description: "iOS styled calculator app.",
        img: "calculator.png",
        live: 'https://calculator.jemalvarez.com/',
        code: 'https://github.com/JemAlvarez/Calculator'
    }, {
        name: "Store",
        date: "2018",
        description: "Store website front-end template.",
        img: "store.png",
        live: 'https://bluestarxd-store.jemalvarez.com/',
        code: 'https://github.com/JemAlvarez/Store-Website-Frontend'
    }
]

export default projects
